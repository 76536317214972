<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 700px">
      <div class="title">充电站添加</div>
      <div class="formDiv">
        <el-form
          ref="FormRules"
          :model="FormData"
          label-position="right"
          label-width="100px"
          :rules="Rules"
        >
          <el-row :gutter="20">
            
            <el-col :span="11">
              <el-form-item label="站点名称:">
                <el-input
                  v-model="FormData.station_name"
                  placeholder="请输入站点名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="选择省份:">
                <el-select v-model="FormData.province" placeholder="请选择省" @change="handleProvinceChange" style="width: 100%;">
                  <el-option v-for="provinces in provinceList" :key="provinces.value" :value="provinces.label" :label="provinces.label"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="选择市:">
                <el-select v-model="FormData.city" placeholder="请选择市"  @change="handleCityChange" style="width: 100%;">
                  <el-option v-for="citys in cityList[0]" :key="citys.value" :value="citys.label">{{ citys.label }}</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="选择区/县:">
                <el-select v-model="FormData.county" placeholder="请选择区/县" style="width: 100%;" >
                  <el-option v-for="countys in countyList[0]" :key="countys.value" :value="countys.label">{{ countys.label }}</el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="社区:">
                <el-input
                  v-model="FormData.community"
                  placeholder="请输入社区名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <div style="display: flex;">
                <el-form-item label="详细地址:">
                <el-input
                  v-model="FormData.station_addr"
                  placeholder="请输入站点地址"
                ></el-input>
              </el-form-item>
              <div class="Btns"  @click="getjw">获取经纬度</div>
            </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="经度:" prop="cord_j">
                <el-input
                  v-model="FormData.cord_j"
                  placeholder="请输入位置经度(0-180°)"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="纬度:" prop="cord_w">
                <el-input
                  v-model="FormData.cord_w"
                  placeholder="请输入位置纬度(0-90°)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!--<el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="枪头总数:">
                <el-input
                  v-model="FormData.plug"
                  placeholder="请输入枪头总数"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="功率:">
                <el-input
                  v-model="FormData.power"
                  placeholder="请输入功率"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>-->
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="车型:">
                <el-select v-model="FormData.type_id" placeholder="请选择充电类型" style="width: 100%;">
                  <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="isadmin">
              <el-form-item label="运营商:">
                <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                  <el-option v-for="username in users" :key="username.id" :value="username.username" :label="username.username"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="!isadmin" v-show="false">
              <el-form-item label="运营商:">
                <el-input
                  v-model="FormData.username"
                  placeholder="请输入所属运营商"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="地址解析:">
                <el-input
                  v-model="address"
                  placeholder="地址解析"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              
            </el-col>
          </el-row> -->
        </el-form>
        <div class="btnDiv" style="width: 100%;">
          <!-- <el-button>录入保存</el-button> -->
          <el-button style="margin: 0 auto;" @click="submit">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { add_station,operator_data } from "@/request/api";
// import ProvinceCityCountry from '../../assets/json/address'
import { ElMessage } from "element-plus";
import {  regionData } from 'element-china-area-data'
import { useRouter } from "vue-router";
// import axios from "axios";
// axios.defaults.withCredentials = true; //请求头携带cookie
// axios.defaults.headers.post["Content-Type"] = "application/json";
import { jsonp } from 'vue-jsonp'
export default {
  name: "SearchMan",
  setup() {
    const router = useRouter();
    const data = reactive({
      //表单数据
      carList:[
          {
            value:2,
            label:'电动车'
          },
          {
            value:4,
            label:'电动汽车'
          }
        ],
        car:'',
      FormData: {
        
      },

      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: '',
      city: '',
      county: '',
      users:[],
      isadmin:false,
      // provinceData: ProvinceCityCountry.address,
    
      Rules: {
        cord_j: [
          {
            required: true,
            message: "经度不能为空",
            trigger: "blur",
          },
        ],
        cord_w: [
          {
            required: true,
            message: "纬度不能为空",
            trigger: "blur",
          },
        ],
     
      },
      address:''
    });

    const initProvinceList =()=> {
      
      // 初始化省份列表数据源    
      regionData.forEach((tab) => {
            data.provinceList.push(tab)
          });

    }
    const handleProvinceChange = (val) =>{
      data.cityList=[]
      data.countyList=[]
      data.province = val
      data.FormData.city = ''
      data.FormData.county = ''
       initCityList()
       
    }

    
    const initCityList=()=> {
      data.provinceList.forEach((tab1)=>{
        if(tab1.label==data.province){
          data.cityList.push(tab1.children)
        }
      })
  }

  const handleCityChange=(value1)=>{
    data.countyList=[]
    data.city = value1
    data.FormData.county = ''
       initCountyList()
    }

    const initCountyList=()=> {
      // 初始化县/区列表数据源
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2)=>{
        if(tab2.label==data.city){
          data.countyList.push(tab2.children)
        }
      })
    }

    const getjw = () =>{
      if(data.FormData.station_addr){
        jsonp('https://apis.map.qq.com/ws/geocoder/v1', {
          address: data.FormData.province + data.FormData.city + data.FormData.county + data.FormData.station_addr,
          // keyword: query,
          key: "2WLBZ-6NZCM-KUC6F-6QIDN-IO5E5-ZABPX", 
          output: 'jsonp'
        }).then(res => {
          // ...
          console.log(res,'ssss');
          data.FormData.cord_j = res.result.location.lng
          data.FormData.cord_w = res.result.location.lat
        })
      }else{
        ElMessage.error('请输入地址后查询')
      }
      

     
    }
    const submit = () => {
      if(!data.FormData.cord_j || !data.FormData.cord_w){
        ElMessage.error("经纬度不能为空！");
      }else{
      const dataa = data.FormData
        add_station(dataa).then((res) => {
          if (res) {
            if (res.code == 200) {
              ElMessage({
                showClose: true,
                message: "新增成功",
                type: "success",
              });
              // data.FormData = {}
              router.push("/device_manage/station_search");
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("提交失败");
          }
        });
      }
     
    };


    const getUsername = () => {
      const username1 = localStorage.getItem('username')
      const users1 = []
      operator_data().then((res) => {
        data.users = res
        res.forEach((a)=>{    
          users1.push(a.username)
      })
      if(users1.indexOf(username1)==-1){
        data.isadmin = true
      }else{
        data.isadmin = false
        data.FormData.username = username1
        
      }
      });
    };

    const handleChange = (value) => {
      console.log(value,'000')
    }

   
    onMounted(() => {
      
      initProvinceList()
      getUsername()
      
      
    });
    return {
      ...toRefs(data),
      getjw,
      submit,
      getUsername,
      handleChange,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
    };
  },
};
</script>
<style>
  .Btns{
    color: #fff;
    background-color: #03beb0;
    border-color: #03beb0;
    width: 100px;
    height: 40px;
    margin-left: 40px;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    border-radius: 40px;
    /* margin-top: -20px;  */
  }
</style>